import React, { useEffect, useState } from "react";
import { MakeAxiosRequest } from "../../utils/handler";
import ModalBox from "../../Components/modals/ModalBox";
import FormFields from "../../Components/inputs/FormFields";
import { SwalError, SwalSuccess } from "../../Components/SwalFns";
import { useLocation, useNavigate } from "react-router-dom";
import MembershipIcon from "../../Assets/Images/membershipIcon.png";
function MembershipPendingModal({ showModal, closeModal }) {

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [prefillDetails, setPrefillDetails] = useState([]);
  const [subDescription, setSubDescription] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');

  const fetchData = async () => {
    try {
      const res = await MakeAxiosRequest("post", "/getmembershipsubscription");
      if (res.status == "1") {
        setPrefillDetails(res.data.membership_details);
        setSubDescription(
          res.data.membership_details.yearly_payment_info
            .subscription_description
        );
      } else {
        SwalError({ msg: res.message });
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchData();
    setSubmitDisabled(false);
    return () => {
      setSubmitDisabled(false);
    };
  }, [showModal]);

  useEffect(() => {
    // Set the default selected plan based on prefillDetails
    if (prefillDetails?.default_subscription_type === "anually") {
      setSelectedPlan(prefillDetails?.yearly_payment_info?.subscription_uuid);
    } else {
      setSelectedPlan(prefillDetails?.monthly_payment_info?.subscription_uuid);
    }
  }, [prefillDetails]);

  const handlePlanChange = (key) => {
    setSelectedPlan(key);
  };

  const submit = async () => {
    setSubmitDisabled(true);
    try {
        const response = await MakeAxiosRequest("post", '/membershipsubscriptiontransactionach', { subscription_uuid: selectedPlan,transactionkey: '' });
        if (response.status == 1) {
          const originalPath = location.pathname;

          navigate('/membership/billinginfo', { state: { responseData: response.data, originalPath } });
        } else if (response.status == 0) {
          SwalError({ msg: response.message });
        } else {
          console.log(response.message);
        }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const cancelPayment = async () => {
    closeModal();
    // navigate(-1);
  };

  return (
    <ModalBox
      isShow={showModal}
      handleClose={cancelPayment}
      modalSize="lg"
      hasData={!isLoading}
    >
      <div className="text-center">
        <img className="membrship-logo" src={MembershipIcon}/>
        <h3 className="modal-title">{prefillDetails.title}</h3>
        <p>{prefillDetails.subtitle}</p>
      </div>
      <div className="form-group mt-4">
        <div className="row">
          <div className="col-6">
            <div className="membrshp-bill" onClick={()=>handlePlanChange(prefillDetails?.monthly_payment_info?.subscription_uuid)}>
            <div className="rdio rdio-primary radio-inline">             
            <input
              type="radio"
              name="plan"
              value={prefillDetails?.monthly_payment_info?.subscription_uuid}
              checked={selectedPlan === prefillDetails?.monthly_payment_info?.subscription_uuid}
              onChange={handlePlanChange}
              id={`plan-`+prefillDetails?.monthly_payment_info?.subscription_uuid}
            />
            <label htmlFor={`plan-`+prefillDetails?.monthly_payment_info?.subscription_uuid}>
              {prefillDetails?.monthly_payment_info?.subscription_title}
            </label>
            <h4>{prefillDetails?.monthly_payment_info?.subscription_amount}</h4>
            <p>For Occasional Users</p>
            </div>
            </div> 
          </div>

          <div className="col-6">
          <div className="membrshp-bill" onClick={()=>handlePlanChange(prefillDetails?.yearly_payment_info?.subscription_uuid)}>
          <div className="rdio rdio-primary radio-inline"> 

            <input
              type="radio"
              name="plan"
              value={prefillDetails?.yearly_payment_info?.subscription_uuid}
              checked={selectedPlan === prefillDetails?.yearly_payment_info?.subscription_uuid}
              id={`plan-`+prefillDetails?.yearly_payment_info?.subscription_uuid}
            />
            <label htmlFor={`plan-`+prefillDetails?.yearly_payment_info?.subscription_uuid} >
              {prefillDetails?.yearly_payment_info?.subscription_title}
            </label>
            <h4>{prefillDetails?.yearly_payment_info?.subscription_amount}</h4>
            <p>{prefillDetails?.yearly_payment_info?.offer_text}</p>
          </div>
          </div>
        </div>
          <div className="col-12 mt-3">

            <h4>{prefillDetails.descriptiontittle}</h4>
            <ul>
              {subDescription.map((des, i) => (
                <li>{des.description}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <button
            type="button"
            className="btn btn-outline-secondary rounded-pill w-100 p-2"
            onClick={cancelPayment}
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            type="submit"
            className="btn btn-primary rounded-pill w-100 p-2"
            onClick={submit}
            disabled={isSubmitDisabled}
          >
            Join Now
          </button>
        </div>
      </div>
    </ModalBox>
  );
}

export default MembershipPendingModal;
