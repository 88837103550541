import React, { useState,useEffect } from "react";
import ModalBox from "../../Components/modals/ModalBox";
import { FormProvider, useForm } from "react-hook-form";
import { MakeAxiosRequest } from "../../utils/handler";
import { SwalError, SwalSuccess } from "../../Components/SwalFns";
import FormFields from "../../Components/inputs/FormFields";
import Loader from '../../Components/loader/Loader';
import { useNavigate  } from 'react-router-dom';
import { Placeholder } from "react-bootstrap";
function WalletTopup({ showModal, closeModal, listingFn }) {
    const [isLoading, setIsLoading] = useState(true);
    const[amountlist, setAmountList] = useState('');
    const [data, setData] = useState('');
    const [walletdata, setWalletData] = useState('');
    const [rechargeAmount, setRechargeAmount] = useState('');
    const navigate = useNavigate ();

    useEffect(() => {
        getTopupInfo();
    }, []);

    const getTopupInfo = async () => {
        try {
            const response = await MakeAxiosRequest("post", "/wallettopupinfo");
            // console.log(response)
            if (response.status == 1) {
                setData(response.data);
                setWalletData(response.data.wallet_banner);
                const amount = response.data.amounts?.map((item) => ({
                    // Transform each item as needed
                    id: item.value,
                    name: item.amount,
                  }));
                  setAmountList(amount);
                setIsLoading(false);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.error("Error", error);
        }
    }

  const methods = useForm();
  const { handleSubmit, reset, setValue, watch, trigger, getValues } = methods;
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(getValues('purchaseamount'));
  const walletTopupSubmit = async (data) => {
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest("post", "/purchaseamounttransactionach", {...data,transactionkey:''});
      // console.log(response);
      if (response.status == 1) {
        navigate('/wallet/billingpreview', { state: { responseData: response.data } });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        SwalError({ msg: response.message });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };
  const setAmount = (data) =>{
    setValue('purchaseamount',data);
    setSelectedAmount(data);
  }
    const amount= watch('purchaseamount') 
    useEffect(() => {
        setSelectedAmount(amount);
    }, [amount]);
  const fields = [
    {
      name: "purchaseamount",
      label: "Recharge",
      className: "col-md-12 mb-3",
      icon:"$",
      rules: {
        required: "Please enter recharge amount",
      },
      mask: "99999999999",
      type: "numberfield",
      value:rechargeAmount,
      isShow: true,
      placeholder:"0.00",
    },
  ];

  return (
    <ModalBox
      isShow={showModal}
      handleClose={closeModal}
      modalSize="md"
      hasData={true}
    >
           <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(walletTopupSubmit)}>
          <div className="text-center mb-4">
                  <h3 className="modal-title" id="exampleModalLabel">Wallet Recharge</h3>
            </div>
            <div className="form-group mt-4">
              <div className="row">
                <div className="col-12">

                
              <div className="web-card wallet-card modal-wallet-card mb-3">
                  <p className="mb-1">{walletdata.title}</p>
                  <h3 className="mb-0">{walletdata.balance_amount}</h3>
                </div>
                <FormFields fields={fields} />
                <div className="topup-amt py-3">
                    {amountlist?.map((sub, i) => (
                      <a href="javascript:void(0)" className= {`btn btn-outline-sm me-2 ${selectedAmount === sub.id ? 'active' : ''}`} onClick={() => setAmount(sub.id)}>{sub.name}</a>
                    ))}
                </div>
              </div>
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="btn btn-primary rounded-pill w-100 p-2"
                disabled={isSubmitDisabled}
              >
                Continue
              </button>
            </div>
          </form>
        </FormProvider>
      )}
    </>
      
    </ModalBox>
  );
}

export default WalletTopup;
